<template>
  <div class='CourseDiscuss'>
    <StudioAdminTabs :list="tabs"></StudioAdminTabs>
    <!-- 文章评论管理 -->
    <div class="navC">
      <p></p>
      <div class="df">
        <el-button type="success" size="small" plain @click="goaddmsShuCai(0)">+添加新专题</el-button>
        <el-button type="warning" size="small" @click="lookNum">查询剩余推送次数</el-button>
      </div>
    </div>
    <div class="contentBox">
      <!-- 表格 -->
      <div class="tableList">
        <div class="ulBox" v-for="(it, ind) in tableData.data" :key="ind" @click="golookChatDetails(it.group_id)">
          <div class="liBox cp" v-for="(em, dex) in it.group_list" :key="dex">
            <div class="top" v-if="dex == 0">
              <img class="imgBig" :src="em.thumb" alt="">
              <div class="aboutTxt">
                <p class="ellipsis-1">{{ em.title }}</p>
              </div>
              <div class="aboutBtn">
                <!-- 删除 -->
                <el-button v-if="!it.num_push" type="danger" plain size="small" icon="el-icon-delete" circle
                  @click.stop="toDelete(it)"></el-button>
                <!-- 修改 -->
                <el-button v-if="!it.num_push" type="primary" plain size="small" icon="el-icon-edit" circle
                  @click.stop="goaddmsShuCai(it.group_id)"></el-button>
                <el-button type="success" plain size="small" icon="el-icon-share" round
                  @click.stop="tuisong(it)">推送</el-button>
              </div>
            </div>
            <div class="bom" v-else>
              <div class="fsbc">
                <div class="txtBox">
                  <p class="ellipsis-2 fz14">{{ em.title }}</p>
                </div>
                <div class="imgBox">
                  <img class="imgBig" :src="em.thumb" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="bommitSty pl10 pr10 pb10">
            <div class="fsbc pl5 pr5">
              <p class="fz14">{{ it.update_time }}</p>
              <el-button @click.stop="tuisongOld(it)" v-if="it.num_push" type="text">推送记录{{ it.num_push }}条</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="block" v-if="tableData.total">
        <el-pagination background @current-change="handleSearch" :current-page.sync="form.page" :page-size="form.limit"
          layout="prev, pager, next,jumper" :total="tableData.total">
        </el-pagination>
      </div>
      <el-empty v-else description="暂无素材"></el-empty>
    </div>

    <!-- 引用工作室原创文章弹框 -->
    <el-dialog class="diaSty" :title="tabType == 1 ? '推送至' : tabType == 2 ? '本专题推送记录' : '剩余推送次数'" width="1000px"
      :visible.sync="TabVisible" @close="onClose">
      <div class="roleBox">
        <p class="mb20 fz18" v-if="tabType != 1 && tabType != 2">注：如需获得本平台和第三方平台相关公众号/媒体的转发次数，请和平台运营方进行联系。</p>
        <el-table :data="dataList.data" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column align="center" prop="id" label="序号" width="120">
          </el-table-column>
          <el-table-column align="center" prop="type_name" label="类型" width="150" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" prop="title" label="公众号/媒体名称" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" prop="account_type_name" label="归属" width="180" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" v-if="tabType == 3" label="剩余总次数" width="120" :show-overflow-tooltip='true'>
            <template scope="scope">
              <span v-if="scope.row.account_type == 9">不限次数</span>
              <span v-else>{{ scope.row.num_assign_quota }}次</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-if="tabType == 3" prop="num_week_use" label="本周已用" width="120"
            :show-overflow-tooltip='true'>
            <template scope="scope">
              <span>{{ scope.row.num_week_use }}次</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-if="tabType == 3" label="本周可用" width="120" :show-overflow-tooltip='true'>
            <template scope="scope">
              <span style="color: #f00;" v-if="scope.row.account_type == 9">不限次数</span>
              <span style="color: #f00;" v-else>{{ scope.row.num_week_quota }}次</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-if="tabType == 2" prop="create_time" label="推送时间" width="180"
            :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" v-if="tabType == 2" prop="audit_status_tips" label="审核结果" width="150"
            :show-overflow-tooltip='true'>
            <template scope="scope">
              <el-popover v-if="scope.row.audit_status == 2" placement="top" title="" trigger="hover"
                :content="scope.row.audit_msg_newest">
                <el-button type="text" slot="reference">{{ scope.row.audit_status_tips }}</el-button>
              </el-popover>
              <div v-else>{{ scope.row.audit_status_tips }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="tabType == 1" align="center" type="selection" width="120">
          </el-table-column>
        </el-table>
        <div class="block" v-show="dataList.total">
          <el-pagination background @current-change="handleSearch2" :current-page.sync="tabObj.page"
            :page-size="tabObj.limit" layout="prev, pager, next, jumper" :total="dataList.total">
          </el-pagination>
        </div>

      </div>
      <div class="btn mt30" v-if="tabType == 1">
        <el-button class="one" @click="onClose">取消</el-button>
        <el-button type="primary" class="two" :loading="loadingBtn" @click="AuditRole()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as wechatApi from "@/api/teacherStudio/wechatApi"
export default {
  data() {
    return {
      studio_id: "",
      tableData: [],
      total: 0,
      form: {
        page: 1,
        limit: 6,
      },
      group_id: "",
      TabVisible: false,
      dataList: [],
      multipleSelection: [],
      tabType: "",
      tabObj: {
        limit: 10,
        page: 1,
      },
      loadingBtn: false,
      tabs: [
        {
          label: "公众号素材管理",
          count: 0
        },
      ],
    }
  },
  created() {
    this.studio_id = localStorage.getItem('studioId')
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //初始化
    this.getList();
  },
  computed: {
  },
  methods: {
    onClose() {
      this.tabType = ""
      this.dataList = {}
      this.TabVisible = false
    },
    //删除
    toDelete(row) {
      this.$confirm(`确认删除该条素材吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        wechatApi.delGroup({ studio_id: this.studio_id, del_group_id: row.group_id }).then(res => {
          this.$message.success(`删除成功`)
          this.handleReset()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getList() {
      let param = {
        studio_id: this.studio_id,
        limit: this.form.limit,
        page: this.form.page,
      }
      wechatApi.materialArticle(param).then(res => {
        this.tableData = res.data.data
      })
    },
    tuisong(row) {
      this.tabType = 1
      this.group_id = row.group_id
      this._pushAccountList()
    },
    _pushAccountList() {
      let param = {
        studio_id: this.studio_id,
        page: this.tabObj.page,
        limit: this.tabObj.limit,
        push_group_id: this.group_id,
      }
      wechatApi.pushAccountList(param).then(res => {
        this.dataList = res.data
        console.log(this.dataList.data);
        if (this.dataList.data.length) {
          this.TabVisible = true
        } else {
          this.$alert('<p style="font-size:16px;">抱歉，暂无可以推送的公众号或其他媒体！</p><br><p>已推送过的请忽略此提示。</p>', '友情提示', {
            dangerouslyUseHTMLString: true,
            showConfirmButton: false,
          });
        }
      })
    },
    tuisongOld(row) {
      this.tabType = 2
      this.group_id = row.group_id
      this._pushRecordList()
    },
    _pushRecordList() {
      let param = {
        studio_id: this.studio_id,
        page: this.tabObj.page,
        limit: this.tabObj.limit,
        record_group_id: this.group_id,
      }
      wechatApi.pushRecordList(param).then(res => {
        this.dataList = res.data
        console.log(this.dataList);
        this.TabVisible = true
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    AuditRole() {
      let arr1 = []
      let arr2 = []
      if (this.multipleSelection.length) {
        this.multipleSelection.forEach(item => {
          if (item.category == 1) {
            arr1.push(item.id)
          }
          if (item.category == 2) {
            arr2.push(item.id)
          }
        })
      } else {
        this.$message.error("请先选择推送的位置")
        return
      }
      this.$confirm(`确认推送该条素材吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingBtn = true
        let param = {
          studio_id: this.studio_id,
          go_group_id: this.group_id,
          studio_account_id: arr1,
          admin_account_id: arr2,
        }
        wechatApi.goPush(param).then(res => {
          this.$message.success(res.data.msg)
          this.TabVisible = false
          this.loadingBtn = false
          this.handleReset()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    _setIsSend(row) {
      let param = {
        studio_id: this.studio_id,
        set_id: row.id,
        set_send_status: row.is_send == 2 ? 1 : 2
      }
      this.$confirm(`确认${param.set_send_status == 1 ? '' : '取消'}发布该条活动吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        wechatApi.setIsSend(param).then(res => {
          this.$message.success(`${param.set_send_status == 1 ? '' : '取消'}发布成功`)
          this.handleReset()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleSearch(val) {
      this.form.page = val;
      this.getList();
    },
    handleSearch2(val) {
      this.tabObj.page = val;
      if (this.tabType == 1) {
        this._pushAccountList()
      }
      if (this.tabType == 2) {
        this._pushRecordList()
      }
    },
    handleReset() {
      this.form = {
        limit: 10,
        page: 1,
      }
      this.getList();
    },
    goaddmsShuCai(id) {
      if (id) {
        this.$router.push({
          name: "addmsShuCai",
          query: { id: id }
        })
      } else {
        this.$router.push({
          name: "addmsShuCai",
        })
      }
    },
    // 查询剩余推送次数
    lookNum() {
      let param = {
        studio_id: this.studio_id,
        page: this.tabObj.page,
        limit: this.tabObj.limit,
      }
      wechatApi.accountQuotaList(param).then(res => {
        this.tabType = 3
        this.dataList = res.data
        this.TabVisible = true
      })
    },
    // 跳转直播详情
    golookChatDetails(id) {
      this.$router.push({
        name: "lookChatDetails",
        query: { id: id }
      })
    },

  },
}
</script>
<style lang="less" scoped>
.CourseDiscuss {
  .navC {
    width: 100%;
    height: 60px;
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    box-sizing: border-box;
  }

  //内容盒子
  .contentBox {
    padding: 0 26px;

    //表格
    .tableList {
      width: 1054px;
      column-count: 3;
      column-gap: 29px;

      .ulBox {
        height: auto;
        margin-bottom: 29px;
        border-radius: 8px;
        break-inside: avoid;


        &:hover {
          box-shadow: 0 0 10px 0 #46b0fe;

          .liBox {

            .top {
              .aboutBtn {
                display: block;
              }
            }
          }
        }

        .liBox {
          &:not(:first-child) {
            border-left: 1px solid #e4e8eb;
            border-right: 1px solid #e4e8eb;
          }

          .top {
            position: relative;
            width: 100%;
            height: 184px;

            img {
              width: 100%;
              height: 184px;
              border-radius: 8px 8px 0 0;
            }

            .aboutTxt {
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .3));
              width: 100%;
              height: 32px;
              line-height: 32px;
              color: #fff;
              padding: 0 10px;
              box-sizing: border-box;
            }

            .aboutBtn {
              display: none;
              position: absolute;
              top: 8px;
              right: 8px;
            }
          }

          .bom {
            width: 100%;
            height: 71px;
            padding: 0 10px;
            box-sizing: border-box;

            >div {
              border-bottom: 1px solid #ccc;
              padding: 5px 0;

              .txtBox {
                width: 230px;
              }

              .imgBox {
                width: 60px;
                height: 60px;

                img {
                  width: 100%;
                  height: 60px;
                }
              }
            }
          }
        }

        .bommitSty {
          border-left: 1px solid #e4e8eb;
          border-right: 1px solid #e4e8eb;
          border-bottom: 1px solid #e4e8eb;
          border-radius: 0 0 8px 8px;

          >div {
            background: rgba(238, 246, 255, 1);
            height: 80px;
          }
        }
      }
    }


  }
}

//样式
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.diaSty {
  .roleBox1 {
    width: 280px;
    margin: 0 auto;

    .tx1 {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      margin-bottom: 14px;

      i {
        color: #ff8201;
        font-size: 18px;
      }

      span {
        font-weight: 700;
      }
    }

    .tx2 {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      margin-bottom: 42px;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .one {
      width: 148px;
      height: 42px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 700;
      color: #333333;
    }

    .two {
      width: 148px;
      height: 42px;
      background: #ff8201;
      border-radius: 6px;
      border: 1px solid #ff8201;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
    }
  }
}
</style>
