import { render, staticRenderFns } from "./msShuCai.vue?vue&type=template&id=0423451a&scoped=true"
import script from "./msShuCai.vue?vue&type=script&lang=js"
export * from "./msShuCai.vue?vue&type=script&lang=js"
import style0 from "./msShuCai.vue?vue&type=style&index=0&id=0423451a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0423451a",
  null
  
)

export default component.exports